.Contact {
	min-height: calc(100vh - 144px);
	height: auto;
	background: #fff;
}

.Contact .linksContainer {
	display: flex;
	flex-direction: column;
	padding: 50px;
	align-items: center;
	justify-content: center;
}