html {
  scroll-behavior: smooth;
}

.App {
  font-family: "Montserrat", sans-serif;
  height: auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.App .AppBackground {
	background-color: #fff;
}

body {
	background-image: linear-gradient(to right, #3f3f40, black);
	position: relative;
}

.App .footer {
	display: flex;
    align-items: center;
    justify-content: space-between;
	background-image: linear-gradient(to right, #3f3f40, black);
	text-align: right;
	width: 100%;
	color: #fff;
	padding: 20px 50px;
	font-size: 12px;
	height: 59px;
	position: relative;
}

.App div.alertChip span.homeAlert {
	margin-left: 0;
}

.App div.alertChip.MuiChip-outlinedPrimary {
	color: #404040;
    border: 1px solid #3578a8;
}

.App div.alertChip .MuiChip-deleteIconOutlinedColorPrimary {
    color: rgb(139 139 139 / 70%);
}

.App div.alertChip, .App div.alertChip:active, .App div.alertChip:focus {
	position: absolute;
    z-index: 9;
    left: calc(50% - 172px);
    top: 110px;
    width: 344px;
    height: 42px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.App div.alertChip svg {
	width: 1.4em;
}

.App .footer .footerItem {
	margin: 0 10px;
	padding-bottom: 2px;
}

.App div.footerItemCenter {
	text-align: center;
    color: #fff;
    font-size: 12px;
    position: absolute;
    width: 400px;
    left: calc(50% - 200px);
}

.App .footer .footerItem.linkContainer:hover {
	border-bottom: 1px solid #fff;
	padding-bottom: 1px;
}

.App .footer .footerItem a {
	color: #fff;
}

.App .footer .footerItem a:hover {
	text-decoration: none;
}

.App .navbar-brand {
  font-weight: bold;
}

.App .navbarContainer {
	padding: 3px 15px;
	background-image: linear-gradient(to right, #3f3f40, black);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.App .nestedDropDownContainer {
	position: relative;
}

.App .dropDownContainer {
	color: #fff;
}

.App .pageTitle {
	text-align: center;
	font-size: 18px;
	margin: 40px 20px;
}

.App .dropDownList, .App .nestedDropDownList {
	position: absolute;
	z-index: 999;
	background: #484848;
	border-bottom-right-radius: 5px;
	border-bottom-left-radius: 5px;
	top: 21px;
	overflow: hidden;
	white-space:nowrap;
	display: inline-block;
	font-size: 12px;
	left: 0px;
	padding: 5px;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.App .dropDownList ul, .App .nestedDropDownList ul {
	text-align: left;
	padding-left: 8px;
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

.App .dropDownList ul li, .App .nestedDropDownList ul li {
	padding: 3px 0;
}

.App .nestedDropDownList {
	border-bottom-left-radius: 0;
}

.nestedDropDownList.srDD {
	left: 155px;
}

.nestedDropDownList.aInfo {
	left: 205px;
}

.nestedDropDownList.aInfo.rri {
	top: 70px;
}

.nestedDropDownList.aInfo.ptt {
	top: 21px;
}

.nestedDropDownList.aInfo.aar {
	top: 50px;
}

.nestedDropDownList.srDD.srDD--hr {
	top: 50px;
}

.nestedDropDownList.srDD.srDD--mr {
	top: 70px;
}

.nestedDropDownList.srDD.srDD--ar {
	top: 90px;
}

.nestedDropDownList.srDD.srDD--all {
	top: 110px;
}

.App .nestedDropDownList {
	border-left: 2px solid #000;
}

.App .dropDownList ul, .App .nestedDropDownList ul {
	list-style-type: none;
}

.App .navigation .MuiAppBar-colorPrimary, .App .navigation .MuiAppBar-colorPrimary {
    background-color: #fff;
}

.App .navigation .MuiAppBar-colorPrimary .MuiTab-wrapper {
	color: #333;
	font-size: 14px;
}

.App .navigation .MuiPaper-elevation4 {
	box-shadow: none;
}

.App .navigation .MuiTypography-root {
	border-top: 1px solid lightgrey;
}

.App .navigation .MuiBreadcrumbs-li a.MuiTypography-root {
	color: #3371a0;
}

.App .navigation .MuiBreadcrumbs-li a.MuiTypography-root, .App .navigation .MuiBreadcrumbs-li p.MuiTypography-root {
	font-size: 14px;
}

.App .navigation .MuiBreadcrumbs-li a.MuiTypography-root:hover {
	cursor: pointer;
	text-decoration: none;
}

.App .navigation .MuiTypography-root.MuiBreadcrumbs-root, .App .navigation .MuiBreadcrumbs-li a.MuiTypography-root, .App .navigation .MuiBreadcrumbs-li p.MuiTypography-root {
	border-top: none;
}

.App .navigation header {
	display: flex;
    align-items: center;
}

.App .navigation .MuiTabs-scrollButtons svg.MuiSvgIcon-root {
	font-size: 20px;
	fill: #FF0D3D;
}

.App .logoContainer {
	display: flex;
	align-items: center;
	position: relative;
	margin-left: 12px;
}

.svg.logo {
    height: 42px;
    margin-top: 2px;
}
.svg {
    width: 40px;
    height: 73px;
}

li.yatoLink, li.yatoLink:hover, li.yatoLink:active {
	text-decoration: none;
	list-style-type: none;
	margin: 0;
	padding: 0;
}

li.yatoLink.yatoLink-left {
	padding-left: 30px;
}

.yatoLink a {
	text-decoration: none;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    margin-right: 30px;
    padding-bottom: 3px;
}

.yatoLink.subNav a {
	color: #fff;;
}

.yatoLink a:hover, .yatoLink a:active, .yatoLink.activeLink a {
	cursor: pointer;
	border-bottom: 2px solid #FF0D3D;
	padding-bottom: 1px;
	text-decoration: none;
}

.App .navLinks {
	display: flex;
}

.titleB {
	color: #FF0D3D;
	font-family: "Montserrat", sans-serif;
    margin-left: -12px;
    margin-top: 8px;
}
.title {
    font-size: 15px;
    font-weight: bold;
	font-style: italic;
	font-family: "Montserrat", sans-serif;
}

.title.titleB a span.yato, .title.titleB a span.yato:hover, .title.titleB a span.yato:active {
	color: #FF0D3D;
	font-weight: bold;
    font-style: italic;
    font-size: 15px;
}

.title.titleB a span.yato, .title.titleB a span.yato:hover {
	color: #FFF;
}

.title.titleB a span.linkSpan {
	color: lightgrey;
}

.title.titleB a, .title.titleB a:hover, .title.titleB a:active {
	text-decoration: none;
	color: #678dad;
}

@media only screen and (min-width: 600px) {
	.yatoLink a {
		font-size: 12px;
	}
	.yatoLink a:hover, .yatoLink a:active {
		padding-bottom: 0;
	}
	.title.titleB a span.yato, .title.titleB a span.yato:hover, .title.titleB a span.yato:active {
	    font-size: 24px;
	}
	.svg.logo {
	    height: 73px;
	    margin-top: 6px;
	}
	.App .logoContainer {
	    margin-left: 33px;
	}
	.svg {
	    width: 75px;
	}
	.titleB {
	    margin-left: -42px;
	    margin-top: 21px;
	}
	.title {
	    font-size: 24px;
	}
}




