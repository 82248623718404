.Manuals {
	min-height: calc(100vh - 144px);
	height: auto;
}

.Manuals .linksContainer {
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.Manuals .manualLink {
	margin-bottom: 30px;
	text-align: center;
}

.Manuals .link {
	margin-left: 0px;
}