.Links .linksContainer {
	padding: 50px;
	display: flex;
	flex-direction: column;
}

.Links .link {
    margin-bottom: 30px;
}

.Links .link:active, .Links .link:hover, .Links .link:visited, .Links .link:focus {
	text-decoration: none;
}

.Links {
	min-height: calc(100vh - 144px);
	height: auto;
}

.Links .metarsContainer {
	display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 30px;
    padding-left: 20px;
}

.Links .notamsContainer {
	display: flex;
    align-items: center;
    justify-content: left;
    margin-bottom: 30px;
    padding-left: 20px;
}