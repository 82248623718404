.Reports {
  min-height: calc(100vh - 144px);
  height: auto;
}

.Reports .topBar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Reports .topBar .searchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 14px 18px 24px;
  margin-left: 34px;
}

.Reports .topBar .search .MuiInputBase-root {
  padding: 0 24px;
  font-size: 14px;
}

.Reports .topBar .searchRadio {
  display: flex;
  margin-left: 30px;
}

.Reports .safetyReportsContainer {
  margin: 50px 10%;
  border-radius: 5px;
  border: 1px solid #a5a5a5;
  padding: 24px 25px;
  position: relative;
}

.Reports .safetyReportsContainer.singleCol {
  width: 500px;
  padding: 20px 80px;
  margin: 70px auto;
}

.Reports .safetyReportsContainer .srTooltipWrapper {
  max-width: 1000px;
  width: 100%;
}

.Reports .safetyReportsContainer .srTooltipWrapper .srLink .linkParts {
  margin-left: 30px;
}

.Reports .safetyReportsContainer .srTooltipWrapper .srLink .linkParts .linkPart {
  font-size: 11px;
}

.Reports .safetyReportsContainer .reportCol {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
}

.Reports .safetyReportsContainer .multiColDivider {
  border-right: 1px solid #e3e3e3;
  height: 100%;
  width: 2%;
  position: absolute;
  right: 50%;
  top: 0;
}

.Reports .safetyReportsContainer.columns {
  display: flex;
  justify-content: space-around;
}

.Reports .safetyReportsContainer .srTooltipWrapper {
  margin: 0;
  border-bottom: 1px solid #cacaca;
  padding-left: 23px;
}

.Reports .safetyReportsContainer .srTooltipWrapper.lastEl {
  border-bottom: none;
}

.Reports .safetyReportsContainer .srTooltipWrapper .srLink {
  font-size: 15px;
  padding: 7px 14px;
  border-radius: 7px;
}

.Reports .safetyReportsContainer .srNoContent {
  font-size: 14px;
}

.Reports .safetyReportsContainer .srTooltipWrapper .srLink:hover, .Reports .safetyReportsContainer .srTooltipWrapper .srLink:active {
  background: #efefef;
}


.Reports .safetyReportsContainer .srTooltipWrapper .srTooltip {
  width: 80%;
  max-width: 700px;
  right: 100px;
  font-size: 15px;
}

.Reports .searchContainer .search {
  margin-right: 21px;
}

.Reports .searchContainer .dropdown {
	margin: 0 10px;
  margin-left: 30px;
}

.Reports .searchContainer .dropdown .typeDropdown {
  width: 140px;
}

