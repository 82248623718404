.Dashboard {
	min-height: calc(100vh - 144px);
	height: auto;
	display: flex;
    align-items: center;
    flex-direction: column;
    height: 1100px;
    padding: 45px 20px 50px;
}

.Dashboard .dashboardContainer {
	padding: 40px 20%;
	display: flex;
	flex-direction: column;
	margin-top: -45px;
	height: 400px;
	position: relative;
}

.Dashboard div.rangeOrMonth .ddSection.ddContainer {
	flex-direction: column;
}

.Dashboard .inputsContainer {
	display: flex;
	align-content: center;
	justify-content: center;
}

.Dashboard .dashboardContainer .printBtn {
	position: absolute;
	top: -50px;
	right: 140px;
}

.Dashboard .dRSWrapper {
	display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Dashboard div.reportFooter {
	margin-bottom: 25px;
	position: relative;
}

.Dashboard div.dashboardDD {
	margin-bottom: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.Dashboard div.dateRangeSection {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row !important;
	margin-right: 30px;
}

.Dashboard div.dateRangeSection .dateTitle {
	margin-right: 10px;
	margin-left: 10px;
}

.Dashboard div.dashboardDD.rangeOrMonth {
	flex-direction: column;
}

.Dashboard div.ddWrapper {
    min-height: 180px;
    min-width: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Dashboard div.ddWrapper .ddTitles {
	flex-direction: row;
	display: flex;
}

.Dashboard div.rangeOrMonth .ddContainer {
	padding: 20px;
	border: 1px solid #e7e7e7;
	border-radius: 7px;
	margin-right: 10px;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.Dashboard div.rangeOrMonth .ddContainer.outputSelect {
	flex-direction: column;
}

.Dashboard div.rangeOrMonth .ddContainer .updateBtn {
	margin-top: 12px;
}

.Dashboard div.pageTitle h4 {
	font-size: 26px;
}

.Dashboard .dashboardDD div {
	font-size: 16px !important;
}

.Dashboard .ddTitle {
	font-weight: 600;
	margin-bottom: 3px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.Dashboard .ddTitle .ddTitleText {
	margin-right: 12px;
}

.Dashboard .reportOverview {
	margin-top: 10px;
	border: 1px solid #e3e3e3;
	border-radius: 12px;
	padding: 0 20px;
	font-size: 11px;
	display: none;
}

.reportTitle {
	display: none;
	margin-bottom: 5px;
	text-align: center;
}

.Dashboard .customized-tooltip-content {
	z-index: 9999;
	background: #fff;
	opacity: .8;
	padding: 12px;
	border-radius: 5px;
	width: 100%;
}

.Dashboard .degdReportOverview {
	max-width: 500px;
	margin: auto;
	margin-top: 34px;
}

.toolTipRow {
	font-size: 16px;
}

.Dashboard .sorties-tooltip-content {
	z-index: 9999;
	background: #fff;
	opacity: .8;
	padding: 20px 20px 20px 0;
	border-radius: 5px;
	width: 100%;
}

.Dashboard .sorties-tooltip-content .toolTipRow {
	display: flex;
	padding-left: 5px;
	margin-left: 5px;
	width: 100%;
}

.Dashboard .sorties-tooltip-content .toolTipRow .bold {
	font-weight: 600;
}

.Dashboard .sorties-tooltip-content .toolTipRow .itemA {
	width: 10%;
}

.Dashboard .sorties-tooltip-content .toolTipRow .itemB {
	width: 60%;
	padding-left: 20px;
}

.Dashboard .sorties-tooltip-content .toolTipRow .itemC {
	width: 15%;
}

.Dashboard .sorties-tooltip-content .toolTipRow .itemD {
	width: 15%;
}

.Dashboard .sorties-tooltip-content .toolTipRow .toolTipBullet {
	border-radius: 50%;
	height: 15px;
	width: 15px;
	margin: 0 5px;
}