.Told {
  min-height: calc(100vh - 144px);
  height: auto;
  max-width: 1140px;
  margin: auto;
}

.Told .toldTitle {
	padding: 40px;
	text-align: center;
}

.Told .toldForm {
	padding: 0 40px 30px;
}

.Told span.smallText {
  font-size: 9px;
} 

.Told .notesTitle {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 100%;
}

.Told .toldCalculateResults {
  width: 100%;
  height: 150px;
}

.Told .toldCalculateResults .noteLink {
  text-decoration: underline;
}

.Told .toldCalculateResults {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #39474a;
  font-size: 15px;
  white-space: pre-line;
}

.Told .toldCalculateButtonContainer {
  align-items: baseline;
  justify-content: center;
  display: flex;
}

.Told .toldControls .toldCalculateResults {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 17px;
}

.Told .toldControls {
  padding: 12px 7px;
  border: 1px solid #c3ced2;
  height: 100%;
  border-radius: 5px;
}

.Told .toldTooltip {
  width: 80%;
  max-width: 500px;
  right: 100px;
  font-size: 15px;
}

.Told .gridItemBorderFirst, .Told .gridItemBorder {
  padding: 20px;
}

.Told .gridItemBorder {
  border-top: 2px solid lightgrey;
}

.Told .radioGroupContainer {
  display: flex;
}
.App .Told .radioGroupContainer label span.MuiTypography-root {
  border-top: none;
  font-size: 14px;
}

.Told .radioGroupTitle {
  margin-right: 12px;
  width: 200px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.Told .radioGroupWrapper, .Told .dataTypeRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Told .gridItemBorderFirst, .Told .gridItemBorder {
  padding: 3px 20px;
}