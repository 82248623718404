.SystemSafety {
	min-height: calc(100vh - 144px);
	height: auto;
}

.SystemSafety .linksContainer {
	padding: 50px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
}

.SystemSafety .manualLink {
	margin-bottom: 30px;
	text-align: center;
}