.App .Home, .App .Home .data, .App .Home .homeBG {
  height: calc(100vh - 144px);
}

.Home .lander {
  padding: 80px 0;
  text-align: center;
  min-height: 100vh;
  height: auto;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.Home .notes h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  overflow: hidden;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.Home .data p {
  color: #777;
}

.Home .homeBG {
  width: 100%;
  height: auto;
  overflow: hidden;
  position: relative;
  min-height: 600px;
  max-height: calc(100vh - 144px);
  -webkit-transition: max-height .4s ease;
  transition: max-height .4s ease;
}

.Home .homeBG .ctaContainer {
  width: 100%;
  position: absolute;
  top: 142px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home .homeBG .ctaContainer .ctaInnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  max-width: 500px;
}

.Home .homeBG .ctaContainer .ctaInnerContainer .search .MuiPaper-root .MuiPaper-elevation1 .MuiPaper-rounded {
  display: flex;
}

.Home .homeBG .ctaContainer .ctaInnerContainer .ctaBtn {
  width: 300px;
  height: 50px;
  margin-left: 10px;
  font-size: 14px;
  background: rgba(234, 234, 234, 0.7);
  color: #226fa2;
  font-weight: 500;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.ctaBtn:hover, .ctaBtn:active, .ctaBtn:focus, .ctaBtn:visited {
  text-decoration: none;
  outline:0;
}

.Home .homeBG .ctaContainer .ctaInnerContainer .ctaBtn:hover {
  background: rgba(255, 255, 255, 0.9);
}

.Home .homeBG .ctaContainer .ctaInnerContainer .search {
  width: 295px;
}

.Home .homeBG .ctaContainer .ctaInnerContainer .search .MuiPaper-root {
  background: rgba(255, 255, 255, 0.7);
  height: 58px;
  display: flex;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.Home .homeBG .ctaContainer .ctaInnerContainer .search svg.MuiSvgIcon-root {
  font-size: 26px;
}

.Home .homeBG .ctaContainer .ctaInnerContainer .search .MuiInputBase-root {
  padding: 12px 24px;
  font-size: 18px;
  color: rgba(0, 0, 0, 1);
}

.Home .homeBG .carousel-root {
  height: 100%;
}

.Home .homeBG .carousel-root .carousel.carousel-slider,
.Home .homeBG .carousel.carousel-slider .slider-wrapper,
.Home .homeBG .carousel.carousel-slider .slider-wrapper .slider {
  height: 100%;
}

.Home .safetyReportsContainer {
  padding: 50px;
}

.Home .safetyReportsContainer .srTooltipWrapper {
  max-width: 300px;
  margin: 20px 0;
}

.Home .safetyReportsContainer .srTooltipWrapper .srLink {
  font-size: 15px;
  padding: 7px 14px;
  border-radius: 7px;
}

.Home .safetyReportsContainer .srNoContent {
  font-size: 14px;
}

.Home .safetyReportsContainer .srTooltipWrapper .srLink:hover, .Home .safetyReportsContainer .srTooltipWrapper .srLink:active {
  background: #efefef;
}


.Home .safetyReportsContainer .srTooltipWrapper .srTooltip {
  max-width: 300px;
  right: 100px;
}

.Home .homeBG img {
  opacity: 1;
  position: relative;
  -webkit-transition: opacity .4s ease, opacity .4s ease;
  transition: opacity .4s ease, opacity .4s ease;
  width: 100vw;
  height: auto;
}

.Home .homeBG.homeBGToggled {
  max-height: 0px;
}

.Home .homeBG.homeBGToggled img {
  opacity: 0;
}

.Home .tabContents {
  width: 97%;
  margin: auto;
}

.Home .tabContents ul li {
  font-size: 14px;
}

.Home .miscLinksContainer {
  display: flex;
  flex-direction: row;
  height: 400px;
}

.Home .miscLinksContainer .leftSide {
  border: 1px solid lightgrey;
  border-radius: 7px;
  height: 100%;
  width: 30%;
}

.Home .miscLinksContainer .leftSide .misclink {
  height: 20%;
  border-bottom: 1px solid lightgrey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.Home .miscLinksContainer .leftSide .misclink.misclink--first {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.Home .miscLinksContainer .leftSide .misclink.active {
  background: #f7f7f7;
}

.Home .miscLinksContainer .leftSide .misclink.misclink--last {
  border-bottom: none;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.Home .miscLinksContainer .leftSide .misclink:hover {
  background: #f7f7f7;
  cursor: pointer;
}

.Home .miscLinksContainer .rightSide {
  width: 70%;
}

.Home .miscLinksContainer .rightSide .misclinkData {
  display: flex;
  flex-direction: column;
  padding: 50px 0px 0px 30%;
  width: 100%;
  font-size: 15px;
}

.Home .miscLinksContainer .rightSide .misclinkData .misclinkDataLink {
  font-size: 15px;
  padding: 12px;
}

.Home .miscLinksContainer .rightSide .misclinkData .misclinkDataLink:hover {
  text-decoration: none;
}

.Home .tabContents .buttonContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Home .toldCalculateButton {
  width: 80%;
  color: #7d94ab;
  font-size: 14px;
}

.Home .toldControls .toldCalculateResults {
  width: 100%;
}

.Home .tabContents .buttonContainer .homeButton {
  margin: 12px;
  font-size: 14px;
  font-weight: 600;
  color: #77a0ad;
}

.Home .toldControls {
  padding: 12px 7px;
  border: 1px solid #c3ced2;
  height: 100%;
  border-radius: 5px;
}

.Home .toldCalculateButtonContainer {
  align-items: baseline;
  justify-content: center;
  display: flex;
}

.Home .toldCalculateResults {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 121px;
  color: #39474a;
  font-size: 15px;
  white-space: pre-line;
}

.Home .formTextbox {
  width: 100%;
}

.Home .radioGroupTitle {
  margin-right: 12px;
  width: 200px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.Home .radioGroupContainer {
  display: flex;
}

.Home .gridItemBorderFirst, .Home .gridItemBorder {
  padding: 20px;
}

.Home .gridItemBorder {
  border-top: 2px solid lightgrey;
}

.App .Home .radioGroupContainer label span.MuiTypography-root {
  border-top: none;
  font-size: 14px;
}

.Home .radioGroupWrapper, .Home .dataTypeRadioGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
