.NewPFD {
  padding: 50px 0;
  min-height: calc(100vh - 144px);
  font-family: "Montserrat", sans-serif;
  margin-bottom: 30px;
}

.NewPFDContainer .rightSection h4 {
  margin-top: 0;
  font-size: 20px;
}

.NewPFDContainer .rightSection .issuesContainer {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  height: 220px;
  overflow: hidden;
}

.NewPFDContainer .rightSection .issues {
  border-radius: 5px;
  padding: 15px;
  height: 160px;
  overflow: scroll;
}

.NewPFDContainer .rightSection .issues::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.NewPFDContainer .rightSection .issues::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.NewPFDContainer .rightSection .issuesContainer .issuesTitle {
  padding: 16px;
}

div.issuesTitle div {
  width: 100%;
  text-align: center;
}

div.asn {
  width: 154px;
}

div.flightTime {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 12px 0;
  width: 400px;
  padding-top: 0;
}

div.issuesTitle div i {
  font-size: 12px;
  margin-top: 3px;
}

.flexContainer {
  display: flex !important;
}

.flexSubContainer {
  display: flex !important;
  flex-direction: column;
}

.NewPFDContainer .rightSection .issues .issuesTitle h4 {
  margin-right: 10px;
}

.NewPFDContainer .rightSection .issues .issueBox {
  border-top: 1px solid #e7e7e7;
  padding-top: 10px;
}

.NewPFD .MuiTypography-body1 {
  font-size: 1.2rem !important;
}

.NewPFD .submitBtn {
  margin-top: 34px;
}

.NewPFD .pfdTitle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewPFD .rightSection {
  width:  50%;
  margin-left: 0px;
}

.ReactModalPortal div.modalButtons {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-around;
}

.NewPFDContainer {
  padding: 50px 75px;
  min-height: calc(100vh - 144px);
  height: auto;
  display: flex;
  margin: auto;
  justify-content: baseline;
}

.NewPFD .tmtRadiosContainer {
  padding: 12px 15px;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  width: 400px;
}

.NewPFD .tmtRadios {
  display: flex;
  flex-direction: row;
}

.NewPFD .tmtLegend {
  border: none;
  font-size: 17px;
  font-family: "Montserrat", sans-serif;
  padding: 0;
  color: #333;
  margin: 0;
  margin-bottom: 7px;
}

.NewPFD .reasonInput {
  margin-top: 12px;
}

.NewPFD .quadTitleDegdHL {
  color: #ec776b;
}

.NewPFD .quadTitleFailHL {
  color: #FAFA23;
}

.NewPFD .ctaBtn {
  width: 300px;
  height: 50px;
  margin-left: 10px;
  font-size: 15px;
  background: rgba(234, 234, 234, 0.7);
  color: #226fa2;
  font-weight: 500;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.NewPFD .questionContainer {
	display: flex;
}

.ctaBtn:hover, .ctaBtn:active, .ctaBtn:focus, .ctaBtn:visited {
  text-decoration: none;
  outline:0;
}

.NewPFD .stageWrapper {
  display: none;
}

.NewPFD .stageWrapper.relevantStage {
  display: block;
}

.NewPFD .sqBtn {
  width: auto;
  min-width: 30px;
  padding: 0 5px;
  height: 30px;
  margin-left: 10px;
  font-size: 15px;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #3a3a3a;
  font-weight: 500;
}

.NewPFD .sqBtn.selectedSQ, .NewPFD .sqBtn.selectedSQ:hover {
  background: #777;
  color: #fff;
}

.NewPFD .sqBtn:hover {
  cursor: pointer;
  background: #e0e0e0;
}

.NewPFD .sqBtn:hover, .NewPFD .sqBtn:active, .NewPFD .sqBtn:focus, ,.NewPFD .sqBtn:visited {
  text-decoration: none;
  outline:0;
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12);
}

.NewPFD .ctaBtn:hover {
  background: rgba(255, 255, 255, 0.9);
}

.NewPFD form {
  padding-bottom: 15px;
  margin-top: 35px;
}

.NewPFD form textarea {
  height: 300px;
  font-size: 18px;
  line-height: normal;
}

.NewPFD .formTextbox {
	width: 100%;
}

.NewPFD .radioGroupTitle {
	margin-right: 12px;
}

.NewPFD .radioGroupWrapper, .NewPFD .dataTypeRadioGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.NewPFD .dataTypeRadioGroup label {
	margin-bottom: 0;
}

.NewPFD .codes {
  padding: 20px 0;
  max-width: 400px;
}

.NewPFD .codes .optContainer:last-of-type {
  border-bottom: 1px solid #b1b1b1;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.NewPFD .codes .codeItem.cbItem {
  min-width: 25px;
  width: 25px;
}

.NewPFD .optContainer {
  border-top: 1px solid #b1b1b1;
  border-left: 1px solid #b1b1b1;
  border-right: 1px solid #b1b1b1;
	position: relative;
  display: flex;
  flex-direction: row;
  padding: 3px 20px;
  align-items: center;
  justify-content: center;
}

.NewPFD .ml-11 {
  margin-left: 11px;
}

.NewPFD .mt-sm {
  margin-top: 15px;
}

.NewPFD .mb-sm {
  margin-bottom: 15px;
}

.NewPFD .mr-11 {
  margin-right: 11px;
}

.NewPFD .dateEventSection {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.NewPFD .leftSection {
  width: 50%;
  padding-right: 25px;
}

.NewPFD .dateEventSection .dateTitle {
  margin-right: 8px;
  height: 30px;
}

.NewPFD .leftSection .eNumTitle {
  margin-left: 12px;
  height: 30px;
  margin-right: 8px;
}

.NewPFD .leftSection .eNumInput {
  width: 50px;
}

.NewPFD .optContainer.other {
  border-bottom: 1px solid #b1b1b1;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.NewPFD .optContainer.ew {
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.NewPFD .degSelector {
  right: -150px;
  position: absolute;
  width: 120px;
}

.NewPFD .codesTable {
  margin-top: 20px;
  position: relative;
}

.NewPFD .degFailContainer {
  position: absolute;
  left: 116px;
}

.NewPFD .degFailContainer.dgcTop {
  top: 32px;
  left: 162px;
}

.NewPFD .degFailContainer.dgcBottom {
  left: 120px;
  top: 48px;
}

.NewPFD .dfItem {
  font-size: 12px;
}

.NewPFD .dfItem--dg {
  margin-left: 0px;
}

.NewPFD .dfItem--fl {
  margin-left: 12px;
}

.NewPFD .dfItem--ds {
  margin-left: 57px;
}

.NewPFD .codeItem {
  margin: 0 5px 0 12px;
  min-width: 70px;
  font-weight: 400;
}

.NewPFD .bottomSection {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewPFD .degdsTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
}

.NewPFD .dasChoicesContainer {
  border: 1px solid #ababab;
  padding: 10px;
  top: 150px;
  right: -150px;
  position: absolute;
  width: 120px;
  border-radius: 5px;
}

.NewPFD .dasChoicesContainer .dasDegradesContainer {
  margin-bottom: 14px;
  padding-bottom: 10px;
}

.NewPFD .dasChoicesContainer .dasContainerBorder {
  width: 100%;
  border-top: 1px solid #ababab;
  margin-bottom: 21px;
}

.NewPFD .dasChoices .dasChoice {
  width: 25px;
  padding: 0 5px;
  height: 25px;
  margin: 5px 40px;
  font-size: 12px;
  border: 1px solid #3a3a3a;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #3a3a3a;
  font-weight: 500;
}


.NewPFD .dasChoices .dasChoice.bgRed, .NewPFD .dasChoices .dasChoice.bgRed:hover {
  background-color: #ec776b;
  color: #fff;
}

.NewPFD .dasChoices .dasChoice.bgYellow, .NewPFD .dasChoices .dasChoice.bgYellow:hover {
  background-color: #FAFA23;
}


.NewPFD .dasChoices .dasChoice:hover {
  cursor: pointer;
  background: #e0e0e0;
}

.NewPFD .degSelector.ewSelector {
  top: 0;
}

.NewPFD .dsTitle {
  margin-left: 12px;
}

.NewPFD .dsInner {
  position: relative;
}

.NewPFD img.quadTarget {
	position: absolute;
	top: -5px;
  right: 0px;
  height: 108px;
  left: -2px;
}

.NewPFD div.wrapper { position: relative; width: 100px; height: 100px; border-radius: 50px; }
.NewPFD div.quarter { position: absolute; width: 50px; overflow: hidden; height: 50px; background-color: #f9f8f8; border: 1px solid #a9a8a8; transition: transform .5s, opacity .5s; opacity: .5; }
.NewPFD div.quarter.left-top { left: 0; top: 0; border-top-left-radius: 50px; }
.NewPFD div.quarter.right-top { left: 50px; top: 0; border-top-right-radius: 50px; }
.NewPFD div.quarter.left-bottom { left: 0; top: 50px; border-bottom-left-radius: 50px; }
.NewPFD div.quarter.right-bottom { left: 50px; top: 50px; border-bottom-right-radius: 50px; }
.NewPFD div.wrapper:hover div.quarter { opacity: .5; }
.NewPFD div.quarter:hover { opacity: .6 !important; transform: scale(1.14); cursor: pointer; z-index: 999; background-color: #cacaca; }

.NewPFD .bgYellow, .NewPFD div.quarter.selectedDegrade, .NewPFD div.quarter.selectedDegrade:hover {
  background-color: #FAFA23;
  -webkit-transition: all .34s; transition: all .34s;
}

.NewPFD .bgRed, .NewPFD div.quarter.selectedFail, .NewPFD div.quarter.selectedFail:hover {
  background-color: #ec776b;
  -webkit-transition: all .34s; transition: all .34s;
}

.NewPFD .bgRed {
  color: #fff;
}

.NewPFD div.quarter .halfQuarter {
  position: absolute;
  width: 100px;
  height: 40px;
  z-index: 99999; 
  transition: transform .5s, opacity .5s;
  opacity: 0;
}

.NewPFD div.quarter .halfQuarter:hover {
  opacity: 1;
}

.NewPFD .halfQuarter.left {
  background-color: #FAFA23;
  border: 1px solid #FAFA23;
}

.NewPFD .halfQuarter.right {
  background-color: #ec776b;
  border: 1px solid #ec776b;
}

.NewPFD div.quarter.left-top .halfQuarter.left {
  left: -48px;
  top: 10px;
  transform: rotate(45deg);
}

.NewPFD div.quarter.left-top .halfQuarter.right {
  left: 7px;
  top: 10px;
  transform: rotate(45deg);
}

.NewPFD div.quarter.right-top .halfQuarter.left {
  left: -61px;
  top: 10px;
  transform: rotate(-45deg);
}

.NewPFD div.quarter.right-top .halfQuarter.right {
  left: -33px;
  top: 39px;
  transform: rotate(-45deg);
}

.NewPFD div.quarter.right-bottom .halfQuarter.left {
  left: -48px;
  top: 10px;
  transform: rotate(45deg);
}

.NewPFD div.quarter.right-bottom .halfQuarter.right {
  left: -9px;
  top: -8px;
  transform: rotate(45deg);
}

.NewPFD div.quarter.left-bottom .halfQuarter.left {
  left: -48px;
  top: -3px;
  transform: rotate(-45deg);
}

.NewPFD div.quarter.left-bottom .halfQuarter.right {
  left: -4px;
  top: 10px;
  transform: rotate(-45deg);
}

.NewPFD div.newDiscBtn {
  margin-top: 10px;
  margin-left: 10px;
}

.NewPDF div.discFormLeft {
  margin-top: 30px;
  display: flex;
}

.NewPFD div.discForm {
  margin: 10px 0;
  padding: 5px 10px 10px;
  border: 1px solid #909090;
  border-radius: 5px;
  width: 850px;
}

.NewPFD div.discFormTitle {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  font-weight: 600;
}

.NewPFD div.discFormBody {
  display: flex;
  justify-content: space-around;
}

.NewPFD div.discCheckboxTitle {
  text-align: center;
}

.NewPFD div.discFormBody svg {
  font-size: 30px;
}

.NewPFD div.discFormBody div.discCheckbox {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  margin: 30px;
}

.NewPFD div.discFormBody div.discCheckbox .discCheckboxWrapper {
  width: 50px;
  display: block;
  margin: auto;
  padding: 0;
}

.NewPFD div.discFormActions {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.NewPFD div.discFormActions div.cancelBtn button {
  background-color: #8b8b8b;
  color: #fff;
}

.icawOption:hover {
  filter: brightness(85%);
}

.NewPFD div.discFormBody input[value='FCS CG DEGD'],
.NewPFD div.discFormBody input[value='FCS CH FAIL A'],
.NewPFD div.discFormBody input[value='FCS CH FAIL B'],
.NewPFD div.discFormBody input[value='FCS CH FAIL C'],
.NewPFD div.discFormBody input[value='FCS CH HOT A'],
.NewPFD div.discFormBody input[value='FCS CH HOT B'],
.NewPFD div.discFormBody input[value='FCS CH HOT C'],
.NewPFD div.discFormBody input[value='FCS DEGD'],
.NewPFD div.discFormBody input[value='FCS EHA COLD'],
.NewPFD div.discFormBody input[value='FCS EHA HOT'] {
  background-color: #e1e10a;
  color: #000202;
}

.NewPFD div.discFormBody input[value='FCS ALIGN TNS'] {
  background-color: #000202;
  color: #25b03d;
}

.NewPFD div.discFormBody input[value='FCS DATA FAIL'] {
  background-color: #e33a10;
  color: #ebebeb;
}


.NewPFD div.discFormBody div.icawSelect {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.NewPFD div.discFormBody div.icawSelect input {
  padding: 3px 5px !important;
  font-size: 18px !important;
}

.NewPFD div.discFormBody div.icawInput {
  
}

.NewPFD div.discFormBody div.icawOptionsList {
  max-height: 100px;
  height: 100px;
  overflow: scroll;
}

.NewPFD div.discFormBody div.icawOption:hover {
  cursor: pointer;
}

.NewPFD div.addedDisc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 15px;
  width: 400px;
  margin-bottom: 15px;
}

.NewPFD div.addedDisc div.addedDiscButtons {
  display: flex;
}

.NewPFD div.addedDisc svg.editIcon {
  fill: #4d9a00;
}

.NewPFD div.addedDisc svg.deleteIcon {
  fill: #FF8080;
}

.NewPFD div.timeSelect {
  align-items: center;
}

.NewPFD div.asnWrapper {
  width: 133%;
}

.NewPFD div.successAlertContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.NewPFD div.successAlert {
    font-size: 16px;
}

div#aircraft {
  height: 24px;
}

div#aircraft div {
  min-height: 0;
}

div#aircraft div.react-select__value-container {
  height: 24px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  position: relative;
}

div#aircraft div.react-select__indicators {
  height: 24px; 
}

div#aircraft div.react-select__single-value {
  position: inherit;
}

div#aircraft div.react-select__control {
  border-color: #828080;
  border-radius: 3px;
}

div#aircraft div.react-select__input {
  position: absolute;
  top: 1px;
}

















































