.NewData {
	padding: 50px;
	min-height: calc(100vh - 144px);
	height: auto;
	max-width: 900px;
  margin: auto;
}

.NewData form {
  padding-bottom: 15px;
  margin-top: 35px;
}

.NewData form textarea {
  height: 300px;
  font-size: 18px;
  line-height: normal;
}

.NewData .formTextbox {
	width: 100%;
}

.NewData .radioGroupTitle {
	margin-right: 12px;
}

.NewData .radioGroupWrapper, .NewData .dataTypeRadioGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.NewData .dataTypeRadioGroup label {
	margin-bottom: 0;
}

.NewData .MuiAlert-message {
    padding: 8px 0;
    font-size: 16px !important;
    text-align: center !important;
    width: 100% !important;
}