.Search {
	min-height: calc(100vh - 144px);
	height: auto;
	display: flex;
	flex-direction: column;
}

.Search * {
    font-size: 14px !important;
}

.Search .clearButton * {
	font-size: 12px !important;
}

.Search .clearButton button {
	margin-bottom: 12px;
	margin-top: -12px;
}

.Search .checkBoxIcon {
	width: 14px;
	height: 14px;
	position: relative;
	background-image: url(/checkbox.png);
	background-position: center;
    background-size: 14px;
    background-repeat: no-repeat;
}

.Search .gridQuad {
	width: 40px;
	height: 40px;
	position: relative;
	background-image: url(/target.svg);
	background-position: center;
    background-size: 40px;
    background-repeat: no-repeat;
}

.Search .gridQuad .quadSection {
	position: absolute;
	width: 20px;
	height: 20px;
	display: none;
    opacity: .5;
}

.Search .gridQuad .quadSection.deg {
	background: #FAFA23;
}

.Search .gridQuad .quadSection.fail {
	background: #ec776b;
}

.Search .gridQuad .quadSection.fl {
	left: 0;
    top: 0;
    border-top-left-radius: 40px;
    display: block;
}

.Search .gridQuad .quadSection.fr {
	left: 20px;
    top: 0;
    border-top-right-radius: 40px;
    display: block;
}

.Search .gridQuad .quadSection.al {
	left: 0px;
    top: 20px;
    border-bottom-left-radius: 40px;
    display: block;
}

.Search .gridQuad .quadSection.ar {
	left: 20px;
    top: 20px;
	border-bottom-right-radius: 40px;
	display: block;
}


.Search #ewDegrades {
	position: relative;
}

.Search .degSelector {
	width: 120px;
}

.Search .degSelector.ewSelector {
    height: 118px;
    margin: auto;
    margin-top: 18px;
}

.Search .dsInner {
	position: relative;
}

.Search img.quadTarget {
	position: absolute;
	top: -5px;
	right: 0px;
	height: 108px;
	left: -2px;
}

.Search div.wrapper { position: relative; width: 100px; height: 100px; border-radius: 50px; }
.Search div.quarter { position: absolute; width: 50px; overflow: hidden; height: 50px; background-background-color: #f9f8f8; border: 1px solid #a9a8a8; transition: transform .5s, opacity .5s; opacity: .5; }
.Search div.quarter.left-top { left: 0; top: 0; border-top-left-radius: 50px; }
.Search div.quarter.right-top { left: 50px; top: 0; border-top-right-radius: 50px; }
.Search div.quarter.left-bottom { left: 0; top: 50px; border-bottom-left-radius: 50px; }
.Search div.quarter.right-bottom { left: 50px; top: 50px; border-bottom-right-radius: 50px; }
.Search div.wrapper:hover div.quarter { opacity: .5; }
.Search div.quarter:hover { opacity: .6 !important; transform: scale(1.14); cursor: pointer; z-index: 999; background-color: #cacaca; }

.Search .bgYellow, .Search div.quarter.selectedDegrade, .Search div.quarter.selectedDegrade:hover {
  background-color: #FAFA23;
  -webkit-transition: all .34s; transition: all .34s;
}

.Search .bgRed, .Search div.quarter.selectedFail, .Search div.quarter.selectedFail:hover {
  background-color: #ec776b;
  -webkit-transition: all .34s; transition: all .34s;
}

.Search .bgRed {
  background-color: #fff;
}



.Search .topBarIcon svg {
	font-size: 30px !important;
}

.Search .SearchBody {
	display: flex;
	flex-direction: row;
	border-top: 1px solid #e7e7e7;
}

.Search .SearchBody .datePicker {
	width: 46%;
    padding: 0 1%;
}

.Search .SearchBody .leftDrawer {
	height: auto;
	width: 400px;
	border-right: 1px solid #e7e7e7;
	display: flex;
	flex-direction: column;
	-webkit-transition: width .5s;
    -moz-transition: width .5s;
    -o-transition: width .5s;
    transition: width .5s;
}

.Search .SearchBody .leftDrawer.closed {
	width: 72px;
}

.Search .SearchBody .leftDrawer.closed .topBar .topBarTitle,
.Search .SearchBody .leftDrawer.closed .drawerBody {
	opacity: 0;
	margin-left: -100px;
}

.Search .SearchBody .leftDrawer .drawerBody .filterItem {
	width: 100%;
	padding: 10px 0;
}

.Search .SearchBody .leftDrawer .drawerBody .filterItem .checkBoxGroup {
	margin-left: 40px;
}

.Search .SearchBody .leftDrawer .topBar .topBarTitle {
	display: block;
	font-size: 18px !important;
	font-weight: 200;
    flex-grow: 1;
    text-align: center;
    -webkit-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    opacity: 1;
    margin-left: 0;
}

.Search .SearchBody .leftDrawer .drawerBody {
	padding: 40px 20px;
	border-top: 1px solid #e7e7e7;
	-webkit-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -ms-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
    -o-transition: opacity .3s ease-in-out, margin-left .3s ease-in-out;
     opacity: 1;
     margin-left: 0;
}

.Search .SearchBody .leftDrawer .topBar {
	align-items: center;
	align-content: center;
	padding: 10px 20px;
	justify-content: flex-end;
    display: flex;
}

.Search .SearchBody .leftDrawer .topBarIcon {
	display: flex;
}

.Search .SearchBody .leftDrawer .topBarIcon:hover {
	cursor: pointer;
}

.Search .SearchBody .leftDrawer .topBar svg {
	font-size: 30px;
}

.Search .SearchBody .SearchResults {
	padding: 80px 20px 0 40px;
	width: 100%;
	height: 1100px;
}

.Search .fcsA input#icaw { color: #25b03d !important; margin-right: 100px; background-color: #000202 !important; }
.Search .fcsB input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsC input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsD input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsE input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsF input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsG input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsH input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsI input#icaw { color: #ebebeb !important; margin-right: 100px; background-color: #e33a10 !important; }
.Search .fcsJ input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }
.Search .fcsK input#icaw { color: #000202 !important; margin-right: 100px; background-color: #e1e10a !important; }

.Search span.icawValue {
	height: 28px;
    display: flex;
    padding: 0 7px;
    align-items: center;
}

.Search span.fcsA { color: #25b03d !important; background-color: #000202 !important; }
.Search span.fcsB { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsC { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsD { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsE { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsF { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsG { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsH { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsI { color: #ebebeb !important; background-color: #e33a10 !important; }
.Search span.fcsJ { color: #000202 !important; background-color: #e1e10a !important; }
.Search span.fcsK { color: #000202 !important; background-color: #e1e10a !important; }

.Search .SearchBody .leftDrawer .drawerBody .filterItem .filterItemTitle {
  font-size: 12px;
  margin-bottom: 7px;
}

.Search .SearchBody .leftDrawer .drawerBody .filterTextSearch {
	margin-top: 20px;
}

.Search .SearchBody .leftDrawer .drawerBody .filterItem .filterItem-BtnGroup button.MuiButton-outlinedPrimary {
	background: #fff;
	border-color: #727272;
    color: #000;
}

.Search .SearchBody .leftDrawer .drawerBody .filterItem .filterItem-BtnGroup button.MuiButton-outlinedPrimary.selected {
	background: #727272;
    background-color: #fff;
}

.Search .SearchBody .leftDrawer .drawerBody .filterItem .MuiAccordionDetails-root {
	flex-direction: column;
}

.Search .SearchBody .SearchResults .pfdSearchResult {
	display: flex;
	flex-direction: column;
}