.TimeSelect {
	position: relative;
    display: flex !important;
    flex-direction: row;
}

.TimeSelect .TimeSelectSelector {
	display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #616161;
    border-radius: 3px;
    min-width: 105px;
}

.TimeSelectBox {
    width: 75px;
}

.TimeSelect .TimeSelectSelector:hover {
	cursor: pointer;
}

.TimeSelect .TimeSelectSelector svg {
	font-size: 16px;
}

.TimeSelectDivider {
    display: flex;
    align-items: center;
    font-size: 26px;
    margin: 0 5px;
}

.TimeSelect .TimeSelectChoices {
	display: flex;
	padding: 4px;
    border: 1px solid #8f8f8f;
    border-radius: 3px;
    margin-top: 1px;
    position: absolute;
    top: 25px;
    left: 0;
    z-index: 99;
    background: #fff;
    width: 105px;
}

.TimeSelect .TimeSelectChoicesCol {
	width: 50%;
	height: 200px;
    overflow: scroll;
}

.TimeSelect .TimeSelectChoice {
	text-align: center;
}

.TimeSelect .TimeSelectChoice:hover {
	background-color: #e7e7e7;
	cursor: pointer;
}

.TimeSelect .TimeSelectChoice.selected {
	background-color: dodgerblue;
	color: #fff;
}